@font-face {
  font-family: 'stroke';
  src: local('Distant_Stroke'), url(./fonts/Distant_Stroke.otf) format("opentype");
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

@font-face {
  font-family: 'Cormorant';
  src: local('CormorantGaramond-Regular'), url(./fonts/CormorantGaramond-Regular.ttf) format("truetype");
}

@font-face {
  font-family: 'madina';
  src: local('Madina'), url(./fonts/Madina.ttf) format("truetype");
}

@font-face {
  font-family: 'tenor';
  src: local('TenorSans-Regular'), url(./fonts/TenorSans-Regular.ttf) format("truetype");
}

body {
  background-color: #D4DECB;
}

.App {
  text-align: center;
}

.App-logo {
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  position: sticky;
  top: 0;
  z-index: 999;
  color: white;
  background-color: #ACBD9D;
  opacity: 0.7;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-body {
  padding-top: 2rem;
  text-align: center;
  overflow-x: hidden;
  max-width: 1000px;
  margin: auto;
  min-height: 80vh;
}

.banner {
  background-color: white;
}

.App-navigation {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-auto-flow: row;
}

.App-footer {
  background-color: #ACBD9D;
  margin-top: 2rem;
}

.footer-contact {
  display: grid;
  justify-items: start;
  align-content: center;
  margin-left: 5%;
  position: absolute;
  left: 0px;
  padding-top: 20px;
}

.footer-company {
  display: inline;
  justify-items: center;
}

.footer-logo {
  padding-top: 10px;
  width: 20%;
  max-width: 150px;
  min-width: 80px;
  height: auto;
  /* filter: brightness(0.4); */
}

.footer-virtual{
  margin-right: 5%;
  position: absolute;
  right: 0px;
  padding-top: 20px;
}

.footer-copyright{
  display: grid;
  grid-column-start: 1;
  grid-column-end: 4;
}

.footer-link{
  display: flex;
  column-gap: 10px;
}


@media screen and (max-width: 800px)  {

}

.Tab-button {
  font-size: 1.75rem !important;
  opacity: 1;
  color: white;
}

.App-link {
  color: #61dafb;
}

.logo {
  width: 25%;
  aspect-ratio: 1 / 1;
  max-width: 120px;
  filter: brightness(0.4);
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.slogan {
  margin-top: 1vw !important;
  margin-bottom: 3vw !important;
}

.summary-box {
  display: grid;  
  justify-content: center;
  justify-items: center;
  margin-bottom: 7%;
}

.summary {
  max-width: 100%;
}

.greeting-box{
  position: relative;
  display: flex;
  width: 100%;
  max-height: 500px;
  margin: auto;
  margin-bottom: 5%;
}

.home-image{
  width: 75%;
  max-height: 450px;
  object-fit: cover;
  margin-bottom: 8%;
}

.greeting-text{
  position: absolute;
  font-size: larger;
  width: 45%;
  bottom: 0;
  right: 0;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
}

.cards {
  display: grid;   
  grid-template-columns: repeat(3, 1fr);
  margin: auto;
  justify-content: center;
  max-width: 1200px;  
}

.step-cards {
  display: grid;   
  grid-template-columns: repeat(3, 1fr);
  margin: auto;
  justify-content: center;
  max-width: 1200px;  
}

@media only screen and (max-width: 800px) {
  .cards { 
    grid-template-columns: repeat(2, 1fr);
  }
} 

.infocard {
  position: relative;
  background-color: transparent;
  margin: auto;
  aspect-ratio: 1 / 1;
  width: 95%;
  height: 95%;
  max-width: 400px;  
  max-height: 375px;
  perspective: 1000px; /* Remove this if you don't want the 3D effect */
}

.inner-square {
  margin: auto;
  width: 75%;  
  height: 75%;
}

/* This container is needed to position the front and back side */
.infocard-inner {
  position: relative;
  margin: auto;
  justify-content: center;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
}

/* Do an horizontal flip when you move the mouse over the flip box container */
.infocard:hover .infocard-inner {
  transform: rotateY(180deg);
}

/* Position the front and back side */
.infocard-front, .infocard-back {
  display: flex;
  position: absolute;
  margin: auto;
  width: 100%;
  height: 100%;
  background-color: #ACBD9D;
  -webkit-backface-visibility: hidden; /* Safari */
  backface-visibility: hidden;
}

/* Style the front side (fallback if image is missing) */
.infocard-front {
  mix-blend-mode: multiply;
}

/* Style the back side */
.infocard-back {
  display: flex;
  text-align: center;
  vertical-align: middle;
  transform: rotateY(180deg);
  align-items:center;
}

.image-card {
  /* filter: grayscale(1000%); */
  width: 100%;  
  height: 100%; 
  background: #ACBD9D;
}

.back-description {
  align-items:center;
}

.stepcard {
  position: relative;
  background-color: transparent;
  aspect-ratio: 1 / 1;
  width: 95%;
  max-width: 400px;  
  max-height: 375px;
}

.image-step {
  display: flex;
  position: inherit;
  margin: auto;
  width: 70%;
  height: auto;
  max-width: 100px;
}

.button-booking {
  font-size: xxx-large;
}

.accordionCard {
  margin-bottom: 1.5rem;
  text-align: left;
}

.About-section {
  display: grid;   
  grid-template-columns: 1fr 3fr;
  column-gap: 1.5em;
  width: 95%;
  max-width: 1000px;
  margin: auto;
}

.About-image {
  width: 100%;
  border-radius: 50%;
  /* shape-outside:border-box; */
}

.About-text {
  
}

.Faq-body {
  display: grid;
  width: 95%;
  max-width: 1000px;
  margin: auto;
}

.faq-image{
  width: 100%;
  max-height: 400px;
  margin-bottom: 5%;
  object-fit: cover;
}

.center {
  vertical-align: middle;
  margin: auto;
}
